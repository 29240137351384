<template>
  <v-dialog
    v-model="modalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Edit Data Pegawai</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="mx-7 mt-5">
          <v-row>
            <v-col cols="8">
              <v-row>
                <!-- NIP -->
                <v-col cols="12" md="4" class="mb-n8">
                  <span class="subtitle-2">NIP</span>
                  <v-text-field
                    dense
                    flat
                    outlined
                    class="mt-2"
                    v-model="editedItem.pegawai_nip"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>

                <!-- Nama -->
                <v-col cols="12" md="8" class="mb-n8">
                  <span class="subtitle-2">Nama</span>
                  <v-text-field
                    dense
                    flat
                    outlined
                    class="mt-2"
                    v-model="editedItem.pegawai_nama"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <!-- Nama Jabatan -->
                <v-col cols="12" md="12" class="mb-n8">
                  <span class="subtitle-2">Nama Jabatan</span>
                  <v-text-field
                    dense
                    flat
                    outlined
                    class="mt-2"
                    v-model="editedItem.pegawai_jabatan"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <!-- Foto -->
                <v-col cols="12">
                  <span class="subtitle-2">Foto</span>
                  <v-file-input
                    dense
                    flat
                    outlined
                    prepend-icon
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Pilih Foto pegawai"
                    append-icon="mdi-camera"
                    @change="onFile"
                    ref="avatar"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="4" class="my-auto">
              <div class="mt-2 text-center">
                <v-avatar size="250">
                  <v-img :src="urlImage" class="mx-auto"></v-img>
                </v-avatar>
              </div>
            </v-col>
          </v-row>

          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";

import modalEdit from "@/store/pegawai/modalEdit";
import refreshView from "@/store/pegawai/viewPegawai";

export default {
  computed: {
    modalEdit: {
      get() {
        return modalEdit.state.modalEdit;
      },
      set(value) {
        modalEdit.commit("toggleModal", value);
      },
    },

    editedItem: {
      get() {
        return modalEdit.state.pegawai;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  watch: {
    async modalEdit() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.urlImage =
        this.editedItem.pegawai_foto === ""
          ? process.env.VUE_APP_API_BASE +
            "upload/pegawaiGambar/pegawaiDefault.png"
          : process.env.VUE_APP_API_BASE +
            "upload/pegawaiGambar/" +
            this.editedItem.pegawai_foto;
    },
  },

  created() {
    if (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  data: () => ({
    session: "",
    btnLoading: true,

    pegawai_foto: "",
    urlImage: "",
  }),

  methods: {
    async edit() {
      this.btnLoading = false;

      const data = new FormData();
      data.append("pegawai_id", this.editedItem.pegawai_id);
      data.append("pegawai_nip", this.editedItem.pegawai_nip);
      data.append("pegawai_nama", this.editedItem.pegawai_nama);
      data.append("pegawai_jabatan", this.editedItem.pegawai_jabatan);
      data.append("pegawai_foto", this.pegawai_foto);

      const url = process.env.VUE_APP_API_BASE + "pegawai";
      this.http
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.btnLoading = true;
          this.closeModal();
        });
    },

    onFile(value) {
      this.pegawai_foto = value;
      this.urlImage = URL.createObjectURL(this.pegawai_foto);
    },

    closeModal() {
      this.modalEdit = false;
    },
  },
};
</script>
