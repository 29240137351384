<template>
  <div>
    <v-card class="mb-5" outlined color="white">
      <v-breadcrumbs :items="items" large></v-breadcrumbs>
    </v-card>

    <div>
      <v-row>
        <div class="text-center mb-5" v-if="loading">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>

        <v-col
          v-else
          cols="12"
          md="4"
          v-for="item in pegawais"
          :key="item.pegawai_id"
        >
          <div class="mb-10">
            <v-card class="py-2">
              <v-row no-gutters>
                <v-col cols="12" md="3" class="text-center">
                  <v-avatar color="primary" size="70">
                    <v-img :src="getIMG(item.pegawai_foto)"></v-img>
                  </v-avatar>
                </v-col>

                <v-col v-if="dekstop" cols="12" md="9" class="my-auto">
                  <h3 class="mb-n2">{{ item.pegawai_nama }}</h3>
                  <v-divider class="mt-2 mb-1"></v-divider>
                  <div>
                    <span>{{ item.pegawai_jabatan }}</span>
                  </div>
                </v-col>

                <v-col v-else cols="12" md="9" class="mt-2 text-center">
                  <h3 class="mb-n2">{{ item.pegawai_nama }}</h3>
                  <v-divider class="mt-3 mb-2"></v-divider>
                  <div>
                    <span>{{ item.pegawai_jabatan }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "pegawai",

  data: () => ({
    loading: true,
    dekstop: true,

    pegawais: [],

    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Profil",
        disabled: true,
        href: "",
      },
      {
        text: "Daftar Pegawai",
        disabled: false,
        href: "/profil/pegawai",
      },
    ],
  }),

  created() {
    if (
      this.$vuetify.breakpoint.name === "xs" ||
      this.$vuetify.breakpoint.name === "sm"
    ) {
      this.dekstop = false;
    }
  },

  mounted() {
    this.getData();
  },

  methods: {
    getIMG(value) {
      if (value) {
        return process.env.VUE_APP_API_BASE + "upload/pegawaiGambar/" + value;
      } else {
        return (
          process.env.VUE_APP_API_BASE +
          "upload/pegawaiGambar/pegawaiDefault.png"
        );
      }
    },

    getData() {
      this.loading = true;
      this.http
        .get(process.env.VUE_APP_API_BASE + "pegawai")
        .then((res) => {
          this.pegawais = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
