/* eslint-disable space-before-function-paren */

import axios from 'axios'

export default {
    JP: async () => {
        const url = process.env.VUE_APP_API_BASE + 'jp'
        const response = await axios.get(url)
        return response.data.data
    },

    Grup: async () => {
        const url = process.env.VUE_APP_API_BASE + 'grup'
        const response = await axios.get(url)
        return response.data.data
    },

    Dataset: async () => {
        const url = process.env.VUE_APP_API_BASE + 'dataset'
        const response = await axios.get(url)
        return response.data.data
    },

    Userlvl: () => {
        const reflvl = [
            {
                id: 1,
                nama: 'Super Admin'
            },
            {
                id: 2,
                nama: 'Admin'
            },
            {
                id: 3,
                nama: 'User'
            },
        ]
        return reflvl
    },
}
