<template>
  <v-dialog v-model="modalView" :width="CWidth">
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Detail Pegawai</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="mx-7 mt-5">
          <div class="mt-2 text-center">
            <v-avatar size="200">
              <v-img
                :src="getIMG(viewItem.pegawai_foto)"
                class="mx-auto"
              ></v-img>
            </v-avatar>
          </div>

          <v-row class="mt-2">
            <!-- NIP -->
            <v-col cols="12" md="12" class="mb-n8">
              <v-text-field
                label="NIP"
                dense
                flat
                outlined
                class="mt-2"
                v-model="viewItem.pegawai_nip"
                autocomplete="off"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- Nama pegawai -->
            <v-col cols="12" class="mb-n8">
              <v-text-field
                label="Nama Pegawai"
                dense
                flat
                outlined
                class="mt-2"
                v-model="viewItem.pegawai_nama"
                autocomplete="off"
                readonly
              ></v-text-field>
            </v-col>

            <!-- Pegawai Jabatan -->
            <v-col cols="12">
              <v-text-field
                label="Jabatan"
                dense
                flat
                outlined
                class="mt-2"
                v-model="viewItem.pegawai_jabatan"
                autocomplete="off"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import modalView from "@/store/pegawai/modalView";

export default {
  computed: {
    modalView: {
      get() {
        return modalView.state.modalView;
      },
      set(value) {
        modalView.commit("toggleModal", value);
      },
    },

    viewItem: {
      get() {
        return modalView.state.pegawai;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  created() {
    if (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  data: () => ({
    CWidth: "45%",

    refpegawailvl: [],
  }),

  methods: {
    getIMG(value) {
      if (value) {
        return process.env.VUE_APP_API_BASE + "upload/pegawaiGambar/" + value;
      } else {
        return (
          process.env.VUE_APP_API_BASE +
          "upload/pegawaiGambar/pegawaiDefault.png"
        );
      }
    },

    closeModal() {
      this.modalView = false;
    },
  },
};
</script>
