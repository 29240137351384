import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modalEdit: false,
        pegawai: {
            pegawai_id: "",
            pegawai_nip: "",
            pegawai_nama: "",
            pegawai_jabatan: "",
            pegawai_foto: "",
            pegawai_created_at: ""
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.modalEdit = value
        },
        viewModal(state, value) {
            state.pegawai = value
        }
    }
})
